import React from 'react'
import PropTypes from 'prop-types'
import './button.scss'

/**
 * Main button/CTA component
 *
 * It can act as a button or link depending on provided props.
 * By default, if `link` prop is provided it will render `a` tag,
 * otherwise it will render `button` tag, but this can be fully customized
 */
function Button({ text, backgroundColor = 'primary', link, onClick }) {
  if (!link) {
    return (
      <button className={`button button--${backgroundColor}`} onClick={onClick}>
        {text}
      </button>
    )
  } else {
    return (
      <a className={`button button--${backgroundColor}`} href={link}>
        {text}
      </a>
    )
  }
}

Button.propTypes = {
  /**
   * Text to display inside the button
   */
  text: PropTypes.string.isRequired,
  /**
   * Theme of the button, each theme has own styles
   */
  backgroundColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'white',
    'pink',
    'brown',
  ]),
  /**
   * If link is provided it will render a tag, else it will render button
   */
  link: PropTypes.string,
  /**
   * Callback prop to be used as a click handler
   */
  onClick: PropTypes.func,
}

export default Button
