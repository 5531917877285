import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

//Components
import Menu from './Menu'
import Logo from '../bvv-styleguide/components/navbar/bvv-logo.png'
//Styles
import '../bvv-styleguide/components/navbar/navbar.scss'

/**
 * NavBar component
 * @return React Element
 */
export default function NavBar({
  isMenuOpen,
  toggleMenu,
  menuItems,
  className = '',
  isScrolled = false,
}) {
  return (
    <div
      className={`navbar ${className} ${isScrolled ? 'navbar--white-bg' : ''}`}>
      <div
        className={`navbar__wrapper
        ${isMenuOpen ? 'navbar__wrapper--open' : 'navbar__wrapper--closed'}
      `}>
        <Link to="/" className="navbar__logo">
          <img src={Logo} alt="BVV Logo" />
        </Link>
        <div
          role="button"
          tabIndex={0}
          className={
            isMenuOpen
              ? 'navbar__hamburger navbar__hamburger--open'
              : 'navbar__hamburger'
          }
          onClick={toggleMenu}
          onKeyDown={toggleMenu}>
          <span></span>
        </div>
      </div>
      <Menu items={menuItems} isOpen={isMenuOpen} />
    </div>
  )
}

NavBar.propTypes = {
  /**
   * isMenuOpen
   */
  isMenuOpen: PropTypes.bool,
  /**
   * toggleMenu
   * Function for toggle isMenuOpen local state
   */
  toggleMenu: PropTypes.func,
  /**
   * menu Items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * menu item title
       */
      title: PropTypes.string.isRequired,
      /**
       * menu item link
       */
      url: PropTypes.string.isRequired,
      /**
       * menu item target
       */
      target: PropTypes.string,
      /**
       * menu item custom class
       */
      classes: PropTypes.string,
    })
  ),
  /**
   * If we need extra className we can add it in this way
   */
  className: PropTypes.string,
  /**
   * Default val is false
   * When user start scroll after 5px it will be true.
   * We use this for navigation background => false = transparent ; true => $primary-dark;
   */
  isScrolled: PropTypes.bool,
}
