export const formatPostDate = postDate => {
  if (postDate) {
    const date = new Date(postDate)
    const formatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const finalDate = new Intl.DateTimeFormat('is-IS', formatOptions).format(
      date
    )
    return finalDate
  }
  return null
}

/**
 * Google Chrome doesn't support Icelandic locale, so formatPostDate can't be used for Icelandic month names
 * So this is custom function for display Icelandic date
 * @param {date} postDate
 */
export const getMonthName = postDate => {
  if (postDate) {
    const monthNames = [
      'Janúar',
      'Febrúar',
      'Mars',
      'April',
      'Maí',
      'Júní',
      'July',
      'Ágúst',
      'September',
      'Október',
      'Nóvember',
      'Desember',
    ]
    const date = new Date(postDate),
      dateMonth = date.getMonth(),
      dateDay = date.getDate(),
      dateYear = date.getFullYear(),
      fullDate = `${monthNames[dateMonth]} ${dateDay}, ${dateYear}`
    return fullDate
  }

  return null
}
