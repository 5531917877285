import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import MenuImage from '../bvv-styleguide/components/menu/pattern-menu.png'
//Style
import '../bvv-styleguide/components/menu/menu.scss'

/**
 * Menu component
 * @return React Element
 */
export default function Menu({ isOpen, items }) {
  return (
    <div
      className={
        isOpen
          ? 'menu menu--open'
          : isOpen === null
          ? 'menu'
          : 'menu menu--close'
      }>
      <div
        className={
          isOpen ? 'menu__wrapper menu__wrapper--open' : 'menu__wrapper'
        }>
        <div
          className="menu__pattern"
          style={{ backgroundImage: `url(${MenuImage})` }}></div>
        <div className="menu__title">BYGGINGAVETTVANGURINN</div>
        <div className="menu__links">
          {/* Go through menu items array and create links */}
          {items.map((item, index) => (
            <Link
              key={index}
              target={item.target}
              className={
                isOpen
                  ? ` menu__link-item menu__link-item--${index} ${item.classes}`
                  : ` menu__link-item-close menu__link-item-close--${index} ${item.classes}`
              }
              to={item.url}>
              {item.title}
            </Link>
          ))}
        </div>
        <div
          className={
            isOpen
              ? 'menu__info menu__info--open'
              : 'menu__info menu__info--close'
          }></div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  /**
   * isOpen
   */
  isOpen: PropTypes.bool,
  /**
   * Menu items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
      classes: PropTypes.string,
    })
  ),
}
