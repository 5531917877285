import React, { Component } from 'react'
//Icons
import footerLogo from '../bvv-styleguide/components/footer/footer-logo.svg'
import facebookLogo from '../bvv-styleguide/components/footer/facebook.svg'
//Components
import Button from '../bvv-styleguide/components/button/Button'
//Style
import '../bvv-styleguide/components/footer/footer.scss'
//Mailchimp
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      onSubmitMessage: '',
      isSubmited: false,
      isSuccess: null,
    }

    this.submitMailchimp = this.submitMailchimp.bind(this)
  }

  submitMailchimp = e => {
    e.preventDefault()
    this.setState({ isSubmited: true })
    addToMailchimp(this.state.email)
      .then(data => {
        data.result === 'success'
          ? this.setState({ onSubmitMessage: data.msg, isSuccess: true })
          : this.setState({ onSubmitMessage: data.msg, isSuccess: false })
      })
      .catch(err =>
        this.setState({ onSubmitMessage: err.msg, isSuccess: false })
      )
  }
  render() {
    return (
      <section className="footer">
        <div className="footer__wrapper">
          <img className="footer__logo" src={footerLogo} alt="footer logo" />
          <div className="footer__info footer__left">
            <div className="footer__brand">
              <p>BYGGINGAVETTVANGURINN</p>
            </div>
            <div className="footer__info-wrap">
              <div className="footer__info-contact">
                <p>Borgartúni 35</p>
                <p>105 Reykjavík</p>
                <p>bvv@bvv.is</p>
              </div>
              <div className="footer__info-socials">
                <a
                  href="https://www.facebook.com/Byggingavettvangurinn/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={facebookLogo} alt="facebook-logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer__newsletter">
            <div className="footer__mailchimp">
              <p>Fylgstu með fréttum af Byggingarvettvangnum:</p>
              <form>
                <input
                  type="email"
                  placeholder="Netfangið þitt"
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <Button
                  backgroundColor="white"
                  text="Skráðu þig"
                  onClick={this.submitMailchimp}
                />
              </form>
              {this.state.isSubmited ? (
                <div
                  className={
                    this.state.isSuccess
                      ? `footer__response-msg`
                      : `footer__response-msg footer__response-msg--error`
                  }>
                  {this.state.onSubmitMessage}
                </div>
              ) : null}
            </div>
            <p className="footer__copyright">
              © Byggingavettvangurinn {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </section>
    )
  }
}
