import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import NavBar from './NavBar'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(null)
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  useEffect(() => {
    isMenuOpen
      ? document.body.classList.add('menu-open')
      : document.body.classList.remove('menu-open')
  }, [isMenuOpen])

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpApiMenusMenusItems {
            edges {
              node {
                items {
                  title
                  url
                  target
                  classes
                }
              }
            }
          }
        }
      `}
      render={data => {
        let menuItemsData =
          data.allWordpressWpApiMenusMenusItems.edges[0].node.items
        return (
          <>
            <NavBar
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu}
              menuItems={menuItemsData}
              className="production-navigation"
            />
          </>
        )
      }}
    />
  )
}

export default Header
